
import Vue from "vue";
import UserAchievements from "@/components/UserAchievements.vue";
import { Session } from "@/types/interfaces";
import SessionCancellation from "@/components/Session/SessionCancellation.vue";

export default Vue.extend({
  name: "SessionCancelledPage",
  components: {
    UserAchievements,
    SessionCancellation
  },
  data() {
    return {
      session: null,
      // Users data
      mentor: {
        firstName: "Tom",
        lastName: "Johns",
        imgSrc: "profile-photo.png",
        position: "Mentor",
        achievements: [
          {
            icon: { name: "star", color: "#FFBE3D" },
            value: 81,
            maxValue: 100,
            details: "(753 ratings)"
          },
          {
            icon: { name: "achievement", color: "#3E85D0" },
            label: "Life Saver Badge:",
            value: "121 Times"
          },
          {
            icon: { name: "profile", color: "#37A000" },
            text: "Professor"
          },
          {
            icon: { name: "checked", color: "#6C849C" },
            text: "Verified Mentor"
          }
        ]
      },
      mentoree: {
        firstName: "Nadia",
        lastName: "White",
        imgSrc: "comment.png",
        position: "Mentoree",
        achievements: [
          {
            icon: { name: "star", color: "#FFBE3D" },
            value: 81,
            maxValue: 100,
            details: "(753 ratings)"
          },
          {
            icon: { name: "calendar", color: "#37A000" },
            label: "Member Since :",
            value: "Fab 2021"
          },
          {
            icon: { name: "checked", color: "#6C849C" },
            text: "Verified Mentoree"
          }
        ]
      }
    };
  },
  mounted(): void {
    // Get session id from url param
    this.session = this.$store.getters.sessions.find(
      (session: Session) => +this.$route.params.id === session.id
    );
  }
});
